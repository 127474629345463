import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import SEO from '../components/App/SEO';
import metaImage from '../assets/images/top-banner-op-image.png';

const PrivacyPolicy = () => {
    return (
        <Layout>
            <SEO
              title="SNSキャンペーンツール｜プライバシーポリシー"
              desc="ウェブサービスである「リタッチ」（以下「本サービス」といいます。）を運営する株式会社リタッチ（以下「当社」といいます。）は、本サービスのユーザー（以下「ユーザー」という）のプライバシーを尊重し、ユーザーの個人情報およびその他のユーザーのプライバシーに係る情報（以下「プライバシー情報」といいます。）の管理に細心の注意を払います。"
              banner={metaImage}
              type={'Article'}
              pathname={'/privacy'}
              shortName="プライバシーポリシー｜リタッチ"
              createdAt="2021-03-02"
            />
            <Navbar />
            <PageBanner
                pageTitle="プライバシーポリシー"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="プライバシーポリシー"
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">
                                <h3>第１条（総則）</h3>
                                <ol>
                                    <li>ウェブサービスである「リタッチ」（以下「本サービス」といいます。）を運営する株式会社リタッチ（以下「当社」といいます。）は、本サービスのユーザー（以下「ユーザー」という）のプライバシーを尊重し、ユーザーの個人情報およびその他のユーザーのプライバシーに係る情報（以下「プライバシー情報」といいます。）の管理に細心の注意を払います。</li>
                                    <li>当社は、個人情報保護法をはじめとする各法令およびその他の規範を遵守してユーザーから収集した個人情報を適切に取り扱います。また、当社は、個人情報を取り扱う体制の強化、SSL技術の導入等、ユーザーの個人情報の取り扱いについて、継続的な改善を図っています。</li>
                                </ol>
                                <h3>第２条（本ポリシーへの同意、同意の撤回）</h3>
                                <ol>
                                    <li>ユーザーは、問い合わせをする、アカウント登録をする等により当社に自身のプライバシー情報を提供する場合、本ポリシーを熟読し、その内容に同意するものとします。</li>
                                    <li>ユーザーは、当社によるプライバシー情報の使用等について同意を撤回することができます。</li>
                                    <li>本条の本ポリシーへの同意および同意の撤回は、それぞれ当社が定める手段にてなされるものとします。</li>
                                    <li>当社は、クッキー、IPアドレス、アクセスログ等のWEBトラッキング技術を活用してユーザーの行動や嗜好に関する情報を収集します。当社は、ユーザーが本サービスを利用した場合、当該ユーザーが当社によるこれらの技術を利用したプライバシー情報の収集について同意したものとみなします。</li>
                                </ol>
                                <h3>第３条（収集するプライバシー情報）</h3>
                                <ol>
                                    <li>当社は、本サービスの提供に際して、ユーザーから以下の情報を収集または取得します。
                                        <ul><li>ユーザーがフォーム等に入力することにより提供する情報：これには氏名、問い合わせ等に関する情報、メールアドレス、年齢または生年月日等が含まれます。</li>
                                            <li>クッキー、IPアドレス、アクセスログ等のWEBトラッキング技術、アクセス解析ツール等を介して当社がユーザーから収集する情報：これには利用端末やOS、ブラウザ等の接続環境に関する情報、ユーザーの行動履歴や閲覧履歴等に関する情報、購入した商品や閲覧した商品等のユーザーの嗜好に関する情報およびクッキー情報が含まれます。なお、これらの情報にはユーザー個人を特定しうる個人情報に該当する情報は、含まれません。</li>
                                        </ul>
                                    </li>
                                    <li>当社は、適法かつ公正な手段によってプライバシー情報を入手し、ユーザーの意思に反する不正な入手をしません。</li>
                                </ol>
                                <h3>第４条（プライバシー情報の利用目的）</h3>
                                <p>当社は、ユーザーから収集したプライバシー情報を本サービスの運営の目的のために使用します。主な利用目的は、以下のとおりです。</p>
                                <ul>
                                    <li>料金請求、本人確認、認証のため</li>
                                    <li>特定のコンテスト、イベントやプロモーションを推進、主催および開催するため。また、参加者の参加資格の有無の判断、実績のモニタリング、優勝者への連絡および賞や特典の授与のため</li>
                                    <li>集合的な統計学的データその他の集合的もしくは推測上の非個人情報を用意するため（当社や当社のビジネスパートナーは、その目的で収集した情報をそれぞれのサービスを向上させたり、サービスを提供したりするために使用することができます。）</li>
                                    <li>アンケート、懸賞、キャンペーンの実施のため</li>
                                    <li>マーケティング調査、統計、分析のため</li>
                                    <li>システムメンテナンス、不具合対応のため</li>
                                    <li>広告の開発、配信およびその成果確認のため</li>
                                    <li>技術サポートの提供、ユーザーからの問い合わせ対応のため</li>
                                    <li>不正行為または違法となる可能性のある行為を防止するため</li>
                                    <li>本サービスおよび当社の運営する各サービスに関する通知やお知らせをするため</li>
                                    <li>紛争・訴訟等の対応のため</li>
                                </ul>
                                <h3>第５条（プライバシー情報の第三者提供）</h3>
                                <ol>
                                    <li>当社は、ユーザーの個人情報を第三者に開示または提供する場合、その提供先・提供情報内容を開示し、ユーザー本人の同意を得るものとします。なお、当社は、以下の場合を除き、ユーザー本人の事前の同意を得ることなく、個人情報を第三者に開示または提供することはありません。
                                        <ul>
                                            <li>法令等の定めに基づいて開示等を請求された場合</li>
                                            <li>弁護士、検察、警察等から捜査に必要な範囲で開示等を請求された場合</li>
                                            <li>当社の関連会社間で情報を共有する場合</li>
                                            <li>本サービスの提供に必要な範囲で第三者に業務の一部を委託する場合</li>
                                        </ul>

                                    </li>
                                    <li>当社は、個人情報の取り扱いを第三者に委託する場合、個人情報保護法に従って、委託先に対する必要かつ適切な監督を行います。</li>
                                    <li>当社は、第三者が運営するサービス（以下「外部サービス」といいます。）のプラットフォーム等を介して本サービスを提供している場合や外部サービスを利用して広告提供、アプリ配信等をしている場合、当該外部サービスの運営者等とユーザーの個人情報等を共有する場合があります。この場合の外部サービスにおける個人情報等の取り扱いについては、当該運営者の個人情報保護方針等が適用されます。</li>
                                    <li>当社は、合併や分割等で当社の事業を第三者に譲渡する場合または本サービスの一部または全部を第三者に譲渡する場合、本サービスに係るユーザーの個人情報等を当該第三者に提供します。この場合、当社は、本サービスの譲渡日および譲渡先を事前にユーザーに開示し、これに同意できないユーザーの個人情報等の提供は、一切しないものとします。</li>
                                </ol>
                                <h3>第６条（プライバシー情報の管理、保管期間）</h3>
                                <ol>
                                    <li>当社は、ユーザーが本サービスを利用している期間中、当該ユーザーから開示または提供されたプライバシー情報を厳重に管理します。</li>
                                    <li>当社は、当社が保管するプライバシー情報を利用する必要がなくなった場合、当該プライバシー情報を遅滞なく消去するよう努めるものとします。また、ユーザーよりプライバシー情報の削除を要求された場合も、同様とします。</li>
                                </ol>
                                <h3>第７条（ユーザーによる照会等への対応）</h3>
                                <ol>
                                    <li>ユーザーは、当社に対して、当社が保有する自身のプライバシー情報の開示、訂正、追加または削除、および利用停止を請求することができます。</li>
                                    <li>ユーザーは、当社が定める手段によって前項の開示等の請求をするものとします。なお、同請求は、ユーザー本人、法定代理人（ユーザーが未成年者または成年被後見人である場合）または当該請求につきユーザー本人より委任された代理人のみすることができます。</li>
                                    <li>当社は、開示等の請求を受けた場合、当社が定める手段によって本人確認したうえで、相当な期間内にこれに対応します。なお、当社は、法令に基づき開示等をしない決定をした場合、その旨をユーザーに通知するものとします。</li>
                                    <li>当社は、ユーザーからプライバシー情報の削除の請求を受けた場合でも、犯罪や不正の防止のために必要であると判断した場合には、そのユーザーのプライバシー情報の一部を保持し続けることができるものとし、ユーザーは、そのことにあらかじめ同意するものとします。また、プライバシー情報の削除の請求をしたユーザーが利用料金の未納等をしている場合や当該ユーザーとの間で紛争等が発生している場合には、プライバシー情報の削除は、それらの問題の解決後になされるものとします。</li>
                                </ol>
                                <h3>第８条（解析ツール等の使用）</h3>
                                <ol>
                                    <li>当社は、アクセス解析ツールを使用してユーザーの行動履歴等に関する情報を収集します。また、本サービスの提供に係るウェブサイト上に掲載される広告等の一部は、クッキーを利用した外部サービスを利用して表示されます。なお、Googleが提供するサービスについては、Googleのプライバシーポリシーが適用されます。Googleのプライバシーポリシーについては、下記のリンク先をご確認ください。<a href="https://policies.google.com/privacy?hl=ja">https://policies.google.com/privacy?hl=ja</a></li>
                                        <li>ユーザーは、1)自身のブラウザ設定等からクッキーを無効にする、2)それぞれの解析ツール、外部サービスに係るウェブページからオプトアウトする、等の手段により当社によるプライバシー情報の収集を拒否するまたは広告を非表示にすることができます。</li>
                                        <li>前項の設定の変更等は、ユーザー自身の自己責任にてなされるものとし、当社は、設定を変更したこと等により一部の情報が閲覧できない等、ユーザーに損害が生じた場合でも、一切責任を負わないものとします。</li>
                                </ol>
                                <h3>第９条（本ポリシーの変更）</h3>
                                <ol>
                                    <li>当社は、自身の判断にて、本ポリシーを改定することができます。当社は、本ポリシーを改定する場合、緊急性がある場合を除き、事前に当社が適当であると判断する手段にてユーザーにその旨を通知するものとします。</li>
                                    <li>本ポリシーの改定は、改定後のプライバシーポリシーを本サービスにかかるウェブサイトやアプリ上に掲載した時点で効力を生じるものとします。</li>
                                    <li>ユーザーは、本ポリシーの改定に同意することができない場合、当社に対して、第７条に定める手段にて自身のプライバシー情報の削除を要求することができます。</li>
                                </ol>
                                <h3>第１０条（合意管轄、準拠法）</h3>
                                <ol>
                                    <li>本ポリシーは、日本国法に準拠して解釈されるものとします。</li>
                                    <li>本ポリシーに関連して発生する紛争等については、大阪地方裁判所を第一審の専属管轄裁判所とします。</li>
                                </ol>
                                <h3>第１１条（管理責任者）</h3>
                                <p>当社では、個人情報の管理責任者を以下の者として、個人情報の適正な管理および個人情報保護に関する施策の継続的な改善を実施しています。なお、個人情報に関するお問い合わせ、ご相談、第７条の開示等の請求の窓口もこちらをご利用ください。</p>
                                <table className="table ">
                                    <tbody>
                                    <tr>
                                        <td width="161">運営者：</td>
                                        <td width="378">株式会社リタッチ</td>
                                    </tr>
                                    <tr>
                                        <td width="161">窓口となる部署</td>
                                        <td width="378">お客様窓口</td>
                                    </tr>
                                    <tr>
                                        <td width="161">E-メールアドレス：</td>
                                        <td width="378">contact@retach.biz</td>
                                    </tr>
                                    <tr>
                                        <td width="161">開示等の請求の方法</td>
                                        <td width="378">郵送、メール</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p>【制定：令和3年3月2日】</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
};

export default PrivacyPolicy;